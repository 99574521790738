<template>
  <v-container>
    <h1>What's New In CATS</h1>
    <v-card
      width="500px"
    >
      <template v-for="(version, count) in sortedItems">
        <v-list-item :key="`item${version}`">
          <v-list-item-content>
            <span class="text-button">Version {{version}} <span v-if="count === 0">(Current)</span></span>
          </v-list-item-content>
          <v-list-item-action>
            <whats-new-popup
              :slide-data="previousUpdates[version]"
              :version-number="version"
            ></whats-new-popup>
          </v-list-item-action>
        </v-list-item>
        <v-divider :key="`divider${version}`"></v-divider>
      </template>
    </v-card>
  </v-container>
</template>

<script>
import WhatsNewPopup from '@/components/WhatsNewPopup'
const whatsNew = require('../../public/whatsNew.json')
const semverSort = require('semver-sort')

export default {
  name: 'PreviousUpdates',
  components: { WhatsNewPopup },
  data () {
    return {
      previousUpdates: whatsNew
    }
  },
  computed: {
    sortedItems: function () {
      return semverSort.desc(Object.keys(this.previousUpdates))
    }
  }
}
</script>

<style scoped>

</style>
