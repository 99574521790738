<template>
  <v-row>
    <v-col cols="12">
      <div class="text-subtitle-1">{{storeName}}</div>
      <div class="text-h4">{{ pageName }}</div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'PageTitleBlock',
  props: {
    storeName: {
      type: String,
      required: true
    },
    pageName: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
