<template>
  <v-main>
    <page-title-block page-name="Customer Journey Start Points" store-name="Custom Region"></page-title-block>
    <v-col cols="12">
      <GmapMap
        :center="mapCentre"
        :zoom="12"
        map-type-id="terrain"
        style="height: 800px"
      >
        <GmapInfoWindow :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">

        </GmapInfoWindow>
        <template v-for="(store, storeIndex) in storeLocations">
          <gmap-marker
            :key="`${store.code}-store-marker`"
            :position="store.location"
            icon="https://www.wickes.co.uk/_ui/addons/b2caddon/shared/common/images/apple-touch-icon-57x57.png"
          ></gmap-marker>
          <GmapMarker
            v-for="(m, index) in store.customerData"
            :icon="storeIndex | pinColouriser"
            :key="`${store.code}-${index}-index`"
            :position="m.location"
            :clickable="true"
            @click="toggleInfoWindow(m,index)"
          />
        </template>
      </GmapMap>
    </v-col>
  </v-main>
</template>

<script>
import { mapState } from 'vuex'
import PageTitleBlock from '@/components/PageTitleBlock'
const pins = [
  'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
  'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
  'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
  'http://maps.google.com/mapfiles/ms/icons/purple-dot.png',
  'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png',
  'http://maps.google.com/mapfiles/ms/icons/orange-dot.png',
  'http://maps.google.com/mapfiles/ms/micons/ltblue-dot.png',
  'http://maps.google.com/mapfiles/ms/micons/pink-dot.png',
  'http://maps.google.com/mapfiles/ms/icons/green.png',
  'http://maps.google.com/mapfiles/ms/icons/blue.png',
  'http://maps.google.com/mapfiles/ms/icons/red.png',
  'http://maps.google.com/mapfiles/ms/icons/purple.png',
  'http://maps.google.com/mapfiles/ms/icons/yellow.png',
  'http://maps.google.com/mapfiles/ms/icons/orange.png',
  'http://maps.google.com/mapfiles/ms/micons/lightblue.png',
  'http://maps.google.com/mapfiles/ms/micons/pink.png',
  'http://maps.google.com/mapfiles/ms/icons/green.png',
  'http://maps.google.com/mapfiles/ms/icons/ylw-pushpin.png',
  'http://maps.google.com/mapfiles/ms/icons/red-pushpin.png',
  'http://maps.google.com/mapfiles/ms/icons/purple-pushpin.png',
  'http://maps.google.com/mapfiles/ms/icons/blue-pushpin.png',
  'http://maps.google.com/mapfiles/ms/icons/grn-pushpin.png',
  'http://maps.google.com/mapfiles/ms/micons/ltblu-pushpin.png',
  'http://maps.google.com/mapfiles/ms/micons/pink-pushpin.png'
]

const {
  VUE_APP_API_URL
} = process.env

export default {
  name: 'CustomerMap',
  components: { PageTitleBlock },
  data () {
    return {
      mapCentre: { lat: 52.5, lng: -1.5 },
      storeLocations: [],
      testStoreLocations: [
        {
          name: 'Test Store',
          code: '1234',
          location: {
            lat: 54.0,
            lng: 1.2
          },
          customerLocations: [
            {
              lat: 53.1,
              lng: 1.2
            }
          ]
        },
        {
          name: 'Test Store 2',
          code: '3212',
          location: {
            lat: 51.0,
            lng: 1.3
          },
          customerLocations: [
            {
              lat: 53.5,
              lng: 1.8
            },
            {
              lat: 53.2,
              lng: 1.5
            }
          ]
        }
      ],
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        content: '',
        // optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35
        }
      }
    }
  },
  filters: {
    pinColouriser: function (index) {
      return pins[index % pins.length]
    }
  },
  computed: {
    ...mapState('instore', ['stores']),
    storeIds: function () {
      return (this.$route.query.storeIds || '').split(',').map(text => text.trim())
    }
  },
  mounted () {
    this.fetchPointsAll()
  },
  methods: {
    getStoreLocation: function (storeId) {
      return Promise.resolve(`https://hybris-services.external.wickes.co.uk/store-service/${storeId}`)
        .then(url => fetch(url))
        .then(data => data.json())
        .then(data => data[0])
        .then(result => result.location)
        .then(location => ({
          lat: location.latitude,
          lng: location.longitude
        }))
        .catch(err => console.log(err))
    },
    fetchPointsAll: function () {
      const promises = this.storeIds.map(storeId => {
        console.log('Fetching for ', storeId)
        return this.getStoreLocation(storeId)
          .then(location => ({
            code: storeId,
            location
          }))
          .then(storeEntry => {
            console.log(storeEntry)
            // fetch customer data
            return this.fetchPoints(storeEntry.code)
              .then(customerData => ({
                ...storeEntry,
                customerData
              }))
          })
      })
      Promise.all(promises).then(result => (this.storeLocations = result))
    },
    fetchPoints: function (storeId) {
      return this.$auth.getTokenSilently()
        .then(token => fetch(`${VUE_APP_API_URL}/admin/store/${storeId}/customer-locations`, {
          headers: {
            Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
          }
        }))
        .then(response => response.json())
        .then(entries => (entries
          .map(entry => {
            return {
              location: {
                lat: entry.customerLocation.latitude,
                lng: entry.customerLocation.longitude
              },
              orderId: entry.orderId,
              code: storeId
            }
          })
        ))
    },
    toggleInfoWindow: function (marker, idx) {
      console.log('clicked', marker)
      this.infoWindowPos = marker.location
      this.infoOptions.content = `Store: ${marker.code}, OrderNo: ${marker.orderId}`

      // check if its the same marker that was selected if yes toggle
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen
      } else {
        // if different marker set infowindow to open and reset current marker index
        this.infoWinOpen = true
        this.currentMidx = idx
      }
    }
  }
}
</script>

<style scoped>

</style>
